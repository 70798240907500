<template>
  <div class="report-options flex flex-column">
    <p class="card-header-divider">
      Настройки аналитики сравнения с эталонным профилем
    </p>
    <div class="report-options__body">
      <custom-select-2
        label="Группа"
        name="group_id"
        v-model="group"
        :options="groupOptions"
      ></custom-select-2>
      <custom-select-2
        label="Эталонный профиль"
        name="reference_profile_id"
        v-model="currentReference"
        :options="referenceOptions"
      ></custom-select-2>
    </div>
    <div
      v-if="group && currentReference"
      class="report-options__btn flex justify-content-center"
    >
      <button @click="requestForReport" class="btn btn-accent">
        Получить отчет
      </button>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from "@vue/runtime-core";
import CustomSelect2 from "../../../components/Forms/Fields/CustomSelect2.vue";
import { useStore } from "vuex";
import {useRoute} from "vue-router";
export default {
  components: { CustomSelect2 },
  name: "reference-users-report-parametres",
  setup(props, context) {
    const store = useStore(),
    route = useRoute()
    const group = ref(null),
      groupOptions = ref([]),
      referenceOptions = ref([]);
    const selectedGroup = computed(() => store.state.analytics.selectedGroup),
      groups = computed(() => store.state.group.groups),
      referenceProfiles = computed(
        () => store.state.analytics.referenceProfiles
      ),
      expertReferenceProfiles = computed(
        () => store.state.analytics.expertReferenceProfiles
      ),
        currentReference = ref(null)


    onBeforeMount(() => {
      store.dispatch("group/getGroups").then(() => {
        groupOptions.value.push({ id: 0, text: "Все сотрудники"});
        groupOptions.value = groupOptions.value.concat(groups.value.map((group) => {
          return { id: group.id, text: group.name };
        }));
      });
      store.dispatch("analytics/getReferenceProfiles").then(() => {
        referenceProfiles.value.forEach((reference) => {
          referenceOptions.value.push({
            id: reference.id,
            text: reference.title,
          });
        });
        currentReference.value = referenceProfiles.value.find((reference) => reference.id == route.query?.id).id.toString()
      });
    });

    const requestForReport = () => {
      context.emit("reportRequest", {
        group_id: group.value,
        reference_profile_id: currentReference.value,
      });
    };

    return {
      group,
      groupOptions,
      selectedGroup,
      groups,
      requestForReport,
      referenceOptions,
      currentReference
    };
  },
};
</script>
